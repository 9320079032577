import AppHead from 'components/AppHead';
import { SessionProvider } from 'components/useSession';
import { AppType } from 'next/dist/shared/lib/utils';

const _app: AppType = (props) => {
  return (
    <>
      <AppHead />
      <SessionProvider>
        <props.Component {...props.pageProps} />
      </SessionProvider>
      <style jsx global>{`
        body {
          top: 0px !important;
        }
      `}</style>
    </>
  );
};

export default _app;
