import { useReducer } from 'react';
import { Session, SessionAction } from './interfaces';

function reducer(
  state: Session,
  action: SessionAction | SessionAction[]
): Session {
  if (Array.isArray(action)) {
    return action.reduce(
      (prevState, action) => reducer(prevState, action),
      state
    );
  }

  switch (action.type) {
    case 'tenant_set':
      if (JSON.stringify(state.tenant) !== JSON.stringify(action.tenant)) {
        return { ...state, tenant: action.tenant };
      }
      break;

    case 'firebase_set':
      const sf = state.firebase;
      const af = action.firebase;

      if (
        !sf ||
        sf.authApiKey !== af.authApiKey ||
        sf.authDomain !== af.authDomain ||
        sf.authTenantId !== af.authTenantId
      ) {
        return { ...state, firebase: action.firebase };
      }
      break;

    case 'user_set':
      const su = state.user;
      const au = action.user;

      if (!su || su.isLoggedIn !== au.isLoggedIn) {
        return { ...state, user: { ...su, ...au } };
      }

      break;

    case 'hostInit_set':
      if (state.hostInit !== action.hostInit) {
        return { ...state, hostInit: action.hostInit };
      }
      break;
  }

  return state;
}

export default function useSessionState() {
  return useReducer(reducer, {
    firebase: null,
    tenant: null,
    user: {
      isLoggedIn: false,
      getIdToken: async () => undefined,
      logout: async () => {},
    },
    hostInit: false,
  });
}
