import { createContext, FunctionComponent, useContext } from 'react';
import useSessionState from './useSessionState';
export * from './interfaces';

const sessionContext = createContext<ReturnType<typeof useSessionState> | null>(
  null
);

export const SessionProvider: FunctionComponent = (props) => {
  const value = useSessionState();

  return (
    <sessionContext.Provider value={value}>
      {props.children}
    </sessionContext.Provider>
  );
};

export default function useSession() {
  return useContext(sessionContext);
}
